import React, { SVGProps } from 'react'

export const NavAlarmLightIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 19.5C10.2609 20.3887 11.0763 20.9992 12.0025 20.9992C12.9287 20.9992 13.7441 20.3887 14.005 19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C15.6819 3 18.6667 5.98477 18.6667 9.66667C18.6667 15.9298 20 17 20 17H4C4 17 5.33333 15.2969 5.33333 9.66667C5.33333 5.98477 8.3181 3 12 3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
