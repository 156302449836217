import { setUserProperties } from '@/common/utils';
import { SP_TOP_HEADER_TABS, STORAGE_KEY } from '@/common/values';
import { STORY_PLAY_TAB_TYPES } from '@/lib/common-react/types/storyGame';
import { useGetUserQuery } from '@/operations/queries/user/__generated__/getUser.generated';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useGetTopHeaderTabs = () => {
  const router = useRouter();
  const [topHeaderTabs, setTobHeaderTabs] = useState(SP_TOP_HEADER_TABS);
  const { data: userData } = useGetUserQuery({
    onCompleted: (data) => {
      data.getUser &&
        setUserProperties({
          registeredAt: data.getUser.registeredAt,
          isTester: data.getUser.isTester,
          userId: data.getUser.userId,
          email: data.getUser.email,
          language: data.getUser.systemLanguage?.code,
          countryCode: data.getUser.countryCode,
          isAdult:
            data.getUser.certificatedAt && !data.getUser.isExpiredCertification,
          isAnonymous: data.getUser.isAnonymous,
        });
    },
  });

  useEffect(() => {
    const storageInfo = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
    const userInfo = storageInfo && JSON.parse(storageInfo);
    const countryCode = userInfo && userInfo.countryCode;
    const language = userInfo && userInfo.language;

    if (!countryCode || !language) return;

    if (countryCode !== 'KR' && language !== 'ko_KR') {
      // 외국 / 외국어
      setTobHeaderTabs(
        SP_TOP_HEADER_TABS.filter(
          (tab) =>
            tab.type !== STORY_PLAY_TAB_TYPES.webNovel &&
            tab.type !== STORY_PLAY_TAB_TYPES.challenge,
        ),
      );
      return;
    }

    if (countryCode !== 'KR' && language === 'ko_KR') {
      // 외국 / 한국어
      setTobHeaderTabs(
        SP_TOP_HEADER_TABS.filter(
          (tab) => tab.type !== STORY_PLAY_TAB_TYPES.challenge,
        ),
      );
      return;
    }

    if (countryCode === 'KR' && language !== 'ko_KR') {
      // 한국 / 외국어
      setTobHeaderTabs(
        SP_TOP_HEADER_TABS.filter(
          (tab) => tab.type !== STORY_PLAY_TAB_TYPES.webNovel,
        ),
      );
      return;
    }

    setTobHeaderTabs(SP_TOP_HEADER_TABS);
  }, [router.locale, router.pathname, userData]);

  return { topHeaderTabs };
};
