import React, { SVGProps } from 'react'

export const Coin1Icon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M19.2911 13.9215C18.178 18.0098 14.0115 20.4633 9.98513 19.4015C5.95871 18.3398 3.59701 14.1648 4.71012 10.0765C5.82323 5.9882 9.98964 3.5347 14.0161 4.59646C18.0425 5.65823 20.4042 9.83319 19.2911 13.9215Z"
        fill="#FFCF23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4084 14.481C19.9857 19.7064 14.6361 22.8976 9.40844 21.519C4.18082 20.1405 1.16917 14.7444 2.59188 9.51904C4.01458 4.29362 9.36425 1.10244 14.5919 2.48097C19.8195 3.85949 22.8312 9.25555 21.4084 14.481ZM9.9847 19.4025C14.0111 20.4643 18.1775 18.0108 19.2906 13.9225C20.4037 9.83419 18.042 5.65923 14.0156 4.59746C9.98921 3.53569 5.8228 5.98919 4.70969 10.0775C3.59658 14.1658 5.95828 18.3408 9.9847 19.4025Z"
        fill="#E59722"
      />
      <path
        d="M18.9089 13.82C17.8456 17.7233 13.8901 20.0723 10.0742 19.0667C6.25823 18.061 4.02684 14.0815 5.09021 10.1781C6.15359 6.27477 10.1091 3.92575 13.925 4.93142C17.7409 5.93709 19.9723 9.91663 18.9089 13.82Z"
        fill="#FFCF23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4854 14.7637C20.8981 20.5902 14.9469 24.2034 9.09466 22.6611C3.24244 21.1187 -0.0746407 15.0629 1.51265 9.23643C3.09994 3.40995 9.05113 -0.203272 14.9033 1.33905C20.7556 2.88138 24.0726 8.93721 22.4854 14.7637ZM9.85065 19.886C14.1084 21.0081 18.5219 18.3871 19.7084 14.0318C20.8949 9.67653 18.4051 5.2362 14.1474 4.11409C9.88958 2.99197 5.47613 5.61299 4.28963 9.96829C3.10313 14.3236 5.59288 18.7639 9.85065 19.886Z"
        fill="#E59722"
      />
      <path
        d="M14.8879 10.3617C14.5337 9.11469 13.3753 8.19998 12 8.19998H11.998H11.9953C10.7892 8.19998 9.74999 8.90339 9.27347 9.91747L9.27793 9.9178C9.1 10.2966 9 10.7185 9 11.1637C9 12.8007 10.3431 14.1276 12 14.1276C12 14.1276 11.5243 15.8216 10.4844 16.7C10.4844 16.7 14.2672 15.2632 14.9378 11.8202C14.9751 11.6286 15 11.3583 15 11.1637C15 10.8857 14.9604 10.6169 14.8879 10.3617Z"
        fill="#E59722"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 7.12544C16.2453 6.48622 15.3333 6.0031 14.3006 5.73942C10.6312 4.80247 6.82757 6.99098 5.80501 10.6276C5.14419 12.9777 5.80654 15.3575 7.34819 17C5.42128 15.3678 4.52012 12.718 5.25491 10.1048C6.27746 6.46822 10.0811 4.27971 13.7505 5.21666C15.0487 5.54812 16.1561 6.22631 17 7.12544Z"
        fill="#FFDD65"
      />
    </svg>
  )
}
