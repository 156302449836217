import React, { SVGProps } from 'react'

export const AiLogo = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0C3.13401 0 0 3.13401 0 7C0 9.36149 1.16936 11.4499 2.96062 12.7176C2.6827 13.5353 2.1437 14.4214 1.09775 15.4197C1.00942 15.504 0.977656 15.6317 1.01605 15.7483C1.05444 15.8648 1.15618 15.9495 1.27775 15.9662C2.91529 16.19 4.59675 15.2671 5.82905 14.2649C5.95494 14.1625 6.07697 14.0586 6.19467 13.9542C6.45892 13.9844 6.72764 14 7 14H13C16.866 14 20 10.866 20 7C20 3.13401 16.866 0 13 0H7Z"
        fill="#753BBD"
      />
      <path d="M13.75 10.5H12.4896V3.5H13.75V10.5Z" fill="white" />
      <path
        d="M5.25 10.5L7.72242 3.5H9.27859L11.751 10.5H10.4033L8.52717 4.93094H8.46899L6.59286 10.5H5.25ZM10.3451 7.75414V8.7645H6.64619V7.75414H10.3451Z"
        fill="white"
      />
    </svg>
  )
}
