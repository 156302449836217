import React, { useRef } from 'react'
import styles from './SPHeader.module.scss'

export interface ISPHeaderCategory {
  id?: number
  title?: string
  type: string
  genre?: string | null
  showFilter?: boolean
}

export type SPHeaderCategoryListProps = {
  data: {
    categoryList?: ISPHeaderCategory[]
    currentCategoryType?: string
    currentCategoryGenre?: string | null
  }
  actions: {
    onCategoryClick?: ({
      type,
      genre,
      tabId,
      showFilter,
      prevType,
      prevGenre,
      index,
    }: {
      tabId?: number
      type: string
      genre?: string | null
      showFilter?: boolean
      prevType?: string
      prevGenre?: string | null
      index?: number
    }) => void
  }
  isDarkTheme?: boolean
}

export const SPHeaderCategoryList = ({
  data,
  actions,
  isDarkTheme,
}: SPHeaderCategoryListProps) => {
  const tabListRef = useRef<HTMLDivElement>(null)
  const tabItemRef = useRef<HTMLDivElement[]>([])

  const handleCategoryClick = (
    category: ISPHeaderCategory,
    clickedTabItemRef: HTMLDivElement,
    index: number
  ) => {
    const listWidth = tabListRef.current?.scrollWidth ?? 0 // 스크롤할 전체 길이
    const listWidthHalf = (tabListRef.current?.clientWidth ?? 0) / 2 // 스크롤될 부모요소 가로값의 절반 (중앙정렬 기준)
    const targetPosition =
      (clickedTabItemRef?.offsetLeft ?? 0) +
      (clickedTabItemRef?.offsetWidth ?? 0) / 2

    if (targetPosition < listWidthHalf) {
      tabListRef.current?.scrollTo({
        behavior: 'smooth',
        left: 0,
      })
    } else if (listWidth - targetPosition < listWidthHalf) {
      tabListRef.current?.scrollTo({
        behavior: 'smooth',
        left: listWidth,
      })
    } else {
      tabListRef.current?.scrollTo({
        behavior: 'smooth',
        left: targetPosition - listWidthHalf,
      })
    }

    actions.onCategoryClick?.({
      type: category.type,
      genre: category.genre,
      tabId: category.id,
      prevType: data.currentCategoryType,
      prevGenre: data.currentCategoryGenre,
      index,
    })
  }

  return (
    <nav
      ref={tabListRef}
      className={`${styles.categoryList} ${isDarkTheme ? styles.dark : ''}`}
    >
      {data.categoryList?.map((category, index) => (
        <div
          className={`${styles.category}  ${
            !category.genre && category.type === data.currentCategoryType
              ? styles.active
              : ''
          } ${
            category.genre &&
            category.type === data.currentCategoryType &&
            category.genre === data.currentCategoryGenre
              ? styles.active
              : ''
          }`}
          onClick={() =>
            handleCategoryClick(category, tabItemRef.current[index], index)
          }
          key={category.id}
          ref={el =>
            el && tabItemRef.current ? (tabItemRef.current[index] = el) : null
          }
        >
          {category.title}
        </div>
      ))}
    </nav>
  )
}
