import React, { SVGProps } from 'react'

export const ProfileIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.1246 11C14.3338 11 16.1246 9.20914 16.1246 7C16.1246 4.79086 14.3338 3 12.1246 3C9.9155 3 8.12464 4.79086 8.12464 7C8.12464 9.20914 9.9155 11 12.1246 11Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12.1246 14C7.58831 14 3.83586 16.6103 3.21484 20.0051C3.11545 20.5484 3.57235 21 4.12464 21H20.1246C20.6769 21 21.1338 20.5484 21.0344 20.0051C20.4134 16.6103 16.661 14 12.1246 14Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
