import React, { SVGProps } from 'react'

export const SPLogoIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="20"
      viewBox="0 0 98 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_9232_58208)">
        <path
          d="M16.6832 3.44399H18.6397C17.6796 4.08664 16.6832 5.45243 16.6832 8.82783C16.6832 11.7012 17.4252 13.2709 18.5676 14.6084C18.4673 14.6084 18.3618 14.6089 18.2522 14.6095C16.2182 14.6198 12.7149 14.6375 12.7078 11.4123V5.47241C12.7078 4.43822 11.9845 3.84168 11.5571 3.58231C13.7669 3.29932 15.5353 1.80614 16.6832 0V3.44399Z"
          fill="current"
        />
        <path
          d="M8.22459 7.65861C7.55863 7.27399 6.91533 6.99503 6.34486 6.74766C5.08962 6.20334 4.18702 5.81194 4.17158 4.78427C4.16173 4.1249 4.79578 3.59714 5.54038 3.57927C8.40941 3.51088 9.18888 6.46803 9.18888 6.46803L10.4804 3.76429C10.2631 3.77217 9.83161 3.78581 9.21745 3.68571C8.7997 3.6333 8.41619 3.51634 8.00669 3.39145C7.33846 3.18766 6.60099 2.96275 5.5325 2.96275C1.54963 2.96275 0.335501 4.41866 0.358751 6.23863C0.386139 8.37446 3.26068 9.47186 4.99479 10.1335C6.64161 10.7622 7.51112 11.7399 7.5259 12.7345C7.54304 13.8728 6.5559 14.4409 5.48048 14.4432C4.55836 14.4451 3.38107 14.0924 3.34383 12.8628C3.3326 12.4916 3.43171 12.1906 3.57318 11.952C3.65495 11.7871 3.70007 11.6025 3.69771 11.4067C3.69042 10.8361 3.32945 10.1481 2.10271 10.1481C0.686815 10.1481 -0.0116745 11.2169 0.000147567 12.1414C0.030491 14.5283 3.57929 15.0524 5.48304 15.0484C8.06657 15.0434 10.8325 14.3168 10.7866 11.2634C10.7598 9.47647 9.49133 8.3904 8.22459 7.65861Z"
          fill="current"
        />
        <path
          d="M49.287 5.00968C49.0254 4.56837 48.773 3.80219 48.8398 3.01141H43.0259C43.7257 3.27865 44.1164 3.81487 44.5803 4.62639L50.2417 14.5282C50.3073 14.675 50.3364 14.8239 50.35 14.9925C50.3621 15.1437 50.0354 16.2448 49.4055 16.7024C49.0943 16.9284 48.6331 16.9726 48.4676 16.9726C47.7862 16.9726 47.2469 16.5084 46.99 15.9891C46.7098 15.4022 46.178 14.958 45.304 15.0477C44.6934 15.1107 43.5892 15.4558 43.5892 17.0158C43.5892 17.981 44.3078 20 46.6377 20C48.6339 20 49.5398 18.4819 49.9723 17.6156C50.1552 17.2492 52.3767 12.791 52.7874 11.9666C52.8666 11.7866 52.8983 11.6377 52.8983 11.4358C52.8983 11.2212 52.8398 11.0356 52.7505 10.8504C52.7505 10.8504 49.528 5.41582 49.287 5.00968Z"
          fill="current"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7526 2.99809C21.3468 3.35831 18.8848 6.34005 19.2539 9.65801C19.6229 12.976 22.6831 15.3737 26.0888 15.0136C29.4948 14.6534 31.9567 11.672 31.5877 8.35369C31.2187 5.03554 28.1585 2.63786 24.7526 2.99809ZM27.9044 13.9522C26.988 14.3937 25.1315 12.5578 23.7582 9.85174C22.3846 7.14589 22.0144 4.59431 22.9308 4.15243C23.8472 3.71074 25.7039 5.54685 27.0772 8.25271C28.4506 10.9588 28.8208 13.5105 27.9044 13.9522Z"
          fill="current"
        />
        <path
          d="M37.7356 5.91727C38.0367 4.03312 39.4606 3.00008 41.2071 3.00008C42.2416 3.00008 43.2719 4.00123 43.2719 5.14109C43.2719 6.32418 42.2883 7.28326 41.0747 7.28326C40.1499 7.28326 39.3757 6.73302 39.0348 5.9359C38.9633 5.76895 38.8575 5.70728 38.7099 5.71131C38.5811 5.71496 38.491 5.76799 38.4282 5.86194C38.0682 6.45771 37.7356 7.48633 37.7356 8.43157V13.4786C37.7372 14.0946 38.2729 14.8316 38.2729 14.8316H33.1993C33.1993 14.8316 33.7368 14.2994 33.7368 12.856V7.52841C33.7368 4.91631 32.4044 4.02217 32.4044 4.02217C36.1692 3.96031 37.7356 2.35186 37.7356 2.35186V5.91727Z"
          fill="current"
        />
        <path
          d="M72.7954 3.29057C72.7954 3.29057 69.9153 3.15532 67.8817 1.68866V13.4786C67.8803 14.0942 67.3444 14.8316 67.3444 14.8316H72.6295C72.6295 14.8316 71.8807 14.2575 71.8807 13.2779V5.17107C71.8807 3.76185 72.7954 3.29057 72.7954 3.29057Z"
          fill="current"
        />
        <path
          d="M84.3781 13.8123C84.3781 14.62 84.7074 14.8315 84.7074 14.8315H80.3791V13.8475C80.3791 13.8475 79.7011 14.897 77.6675 14.9009C74.7246 14.9062 73.585 13.3839 73.5808 11.7941C73.5757 9.73719 75.6115 8.91817 76.6857 8.54161C78.1051 8.0444 80.1507 7.76889 80.1507 7.76889C78.3461 8.78426 77.399 9.75064 77.4027 11.2058C77.4059 12.4615 78.3776 13.9704 80.3791 13.3253V5.40022C80.3775 4.70147 80.2528 3.56353 79.0481 3.56602C78.2397 3.56775 77.6858 4.16679 77.6858 4.82327C77.6858 5.06571 77.7825 5.22813 77.8832 5.39741C77.9929 5.58161 78.1073 5.77392 78.1073 6.08629C78.1073 6.60041 77.6435 7.20866 76.5129 7.20866C75.1061 7.20866 74.5908 6.2323 74.6336 5.47572C74.7605 3.22116 78.6263 2.96526 79.0663 2.96449C82.1867 2.95796 84.3559 3.98812 84.3624 6.93912L84.3781 13.8123Z"
          fill="current"
        />
        <path
          d="M59.7134 2.7657C55.6057 3.33842 53.5096 3.15917 53.5096 3.15917C53.5096 3.15917 55.1028 3.55167 55.1028 5.23563V13.4784C55.1014 14.0942 54.5655 14.8316 54.5655 14.8316H59.6392C59.6392 14.8316 59.1028 14.0942 59.1018 13.4784L59.1188 3.53438C59.1188 3.53438 59.4776 3.47598 60.1081 3.49423C61.7484 3.54245 62.4983 5.17223 62.4983 7.16511C62.4983 10.671 59.89 11.324 59.5318 11.3743L62.1052 11.3697C64.2904 11.3697 66.3756 10.0865 66.3756 7.09633C66.3756 5.75032 66.0836 5.07751 65.6178 4.48424C64.5577 3.13419 62.4514 2.38395 59.7134 2.7657Z"
          fill="current"
        />
        <path
          d="M89.5928 3.01141C89.5258 3.80219 89.7783 4.56837 90.0397 5.00968C90.2811 5.41582 93.5036 10.8504 93.5036 10.8504C93.5927 11.0356 93.6514 11.2212 93.6514 11.4358C93.6514 11.6377 93.6195 11.7866 93.5402 11.9666C93.1298 12.791 90.9081 17.2492 90.7252 17.6156C90.2927 18.4819 89.3869 20 87.3908 20C85.0606 20 84.3419 17.981 84.3419 17.0158C84.3419 15.4558 85.4464 15.1107 86.057 15.0477C86.9311 14.958 87.4629 15.4022 87.7429 15.9891C88 16.5084 88.5393 16.9726 89.2204 16.9726C89.386 16.9726 89.8474 16.9284 90.1585 16.7024C90.7885 16.2448 91.1149 15.1437 91.1029 14.9925C91.0893 14.8239 91.0604 14.675 90.9948 14.5282L85.3331 4.62639C84.8695 3.81487 84.4788 3.27865 83.7789 3.01141H89.5928Z"
          fill="current"
        />
        <path
          d="M97.5701 4.17193C97.2941 3.21247 96.3911 2.50854 95.3186 2.50854H95.3149C94.3746 2.50854 93.5644 3.04974 93.193 3.83034L93.1965 3.83053C93.0576 4.12179 92.9796 4.44667 92.9796 4.78903C92.9796 6.04878 94.0269 7.06971 95.3186 7.06971C95.3186 7.06971 94.9476 8.37327 94.137 9.04934C94.137 9.04934 97.0862 7.94368 97.6091 5.29431C97.6381 5.14695 97.6572 4.93888 97.6572 4.78903C97.6572 4.5752 97.6269 4.36847 97.5701 4.17193Z"
          fill="current"
        />
      </g>
      <defs>
        <clipPath id="clip0_9232_58208">
          <rect width="98" height="20" fill="current" />
        </clipPath>
      </defs>
    </svg>
  )
}
