import React from 'react'
import { NavAlarmLightIcon } from '../../assets/svgs/NavAlarmLightIcon'
import { SPLogoIcon } from '../../assets/svgs/SPLogoIcon'
import { cn } from '../../utils/classNames'
import styles from './SPHeader.module.scss'
import { STORY_PLAY_TAB_TYPES, StoryPlayTabType } from '../../types/storyGame'
import { Coin1Icon } from '../../assets/svgs/Coin1Icon'
import { SearchIcon } from '../../assets/svgs/SearchIcon'
import { ProfileIcon } from '../../assets/svgs/ProfileIcon'
import {
  SPHeaderCategoryList,
  SPHeaderCategoryListProps,
} from './SPHeaderCategoryList'
import { keyType, t } from '../../utils/translate'
import { AiLogo } from '../../assets/svgs/AiLogo'

interface ISPHeader {
  data: {
    tabList: { type: StoryPlayTabType; title: string }[]
    currentTabType: StoryPlayTabType
  } & Pick<
    SPHeaderCategoryListProps['data'],
    'categoryList' | 'currentCategoryType' | 'currentCategoryGenre'
  >
  actions: {
    onLogoClick?: () => void
    onTabClick?: ({ type }: { type: StoryPlayTabType }) => void
    onStoreIconClick?: () => void
    onSearchIconClick?: () => void
    onProfileIconClick?: () => void
    onNotificationIconClick?: () => void
  } & Pick<SPHeaderCategoryListProps['actions'], 'onCategoryClick'>
  isDarkTheme?: boolean
  maxWidth?: string
  tab:
    | 'home'
    | 'interactive'
    | 'web-novel'
    | 'challenge'
    | 'story-game'
    | 'profile'
}

export const SPHeader = ({
  data,
  actions,
  isDarkTheme,
  maxWidth,
  tab,
}: ISPHeader) => {
  return (
    <header className={`${styles.container} ${isDarkTheme ? styles.dark : ''}`}>
      <nav className={styles.mainHeader} style={{ maxWidth }}>
        <div className={styles.leftWrapper}>
          <div className={styles.mainLogoWrapper} onClick={actions.onLogoClick}>
            <SPLogoIcon
              className={`${styles.mainLogoIcon} ${styles.main}`}
              fill={isDarkTheme ? '#fff' : '#000'}
            />
            <AiLogo />
          </div>
          {tab === 'interactive' && (
            <div
              className={styles.logoIcon}
              onClick={() =>
                actions.onTabClick?.({ type: STORY_PLAY_TAB_TYPES.interactive })
              }
            >
              {t('common_screen_title_interactive')}
            </div>
          )}
          {tab === 'web-novel' && (
            <div
              className={styles.logoIcon}
              onClick={() =>
                actions.onTabClick?.({ type: STORY_PLAY_TAB_TYPES.webNovel })
              }
            >
              {t('common_screen_title_webnovel')}
            </div>
          )}
          {tab === 'challenge' && (
            <div
              className={styles.logoIcon}
              onClick={() =>
                actions.onTabClick?.({ type: STORY_PLAY_TAB_TYPES.challenge })
              }
            >
              {t('common_screen_title_challenge_story')}
            </div>
          )}
          {tab === 'story-game' && (
            <div
              className={styles.logoWrapper}
              onClick={() =>
                actions.onTabClick?.({ type: STORY_PLAY_TAB_TYPES.storyGame })
              }
            >
              <div className={`${styles.logoIcon} ${styles.withAi}`}>
                {t('storygame_screen_title')}
              </div>
              <AiLogo />
            </div>
          )}
          {tab === 'profile' && (
            <div className={styles.logoIcon}>
              {t('profile_screen_title_profile')}
            </div>
          )}
          {data.tabList.map(tabItem => {
            return (
              <div
                className={styles.tabWrapper}
                onClick={() => actions.onTabClick?.({ type: tabItem.type })}
                key={tabItem.type}
              >
                <div
                  className={`${styles.tab} ${
                    tab !== 'profile' &&
                    tabItem.type === data.currentTabType &&
                    styles.active
                  }`}
                >
                  {t(tabItem.title as keyType)}
                </div>
                {tabItem.type === STORY_PLAY_TAB_TYPES.storyGame && <AiLogo />}
              </div>
            )
          })}
        </div>
        <div className={styles.rightWrapper}>
          {actions.onStoreIconClick && (
            <Coin1Icon
              className={styles.icon}
              onClick={actions.onStoreIconClick}
            />
          )}
          {actions.onSearchIconClick && (
            <SearchIcon
              className={styles.icon}
              onClick={actions.onSearchIconClick}
              stroke={isDarkTheme ? '#fff' : '#000'}
            />
          )}
          {actions.onProfileIconClick && (
            <ProfileIcon
              className={cn(styles.icon, styles.profileIcon)}
              onClick={actions.onProfileIconClick}
              color={isDarkTheme ? '#fff' : '#000'}
            />
          )}
          {actions.onNotificationIconClick && (
            <NavAlarmLightIcon
              className={cn(styles.icon, styles.notificationIcon)}
              onClick={actions.onNotificationIconClick}
              color={isDarkTheme ? '#fff' : '#000'}
            />
          )}
        </div>
      </nav>
      {data.categoryList && data.categoryList.length > 0 && (
        <SPHeaderCategoryList data={data} actions={actions} />
      )}
    </header>
  )
}
